import { createSlice } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";

// Initial state
const initialState = {
  frameloop: true,
  updateAll: false,
  phaseBtnText: "design",
};

// Actual Slice
export const frameloopSlice = createSlice({
  name: "frameloop",
  initialState,
  reducers: {
    resetPhase(state) {
      state.phaseBtnText = "design";
    },
    setFrameloop(state, action) {
      state.frameloop = action.payload;
    },
    setUpdateAll(state, action) {
      state.updateAll = action.payload;
    },
    setPhaseBtnText(state, action) {
      state.phaseBtnText = action.payload;
    },
  },

  // Special reducer for hydrating the state. Special case for next-redux-wrapper
  extraReducers: {
    [HYDRATE]: (state, action) => {
      return {
        ...state,
        ...action.payload.frameloop,
      };
    },
  },
});

export const { resetPhase, setFrameloop, setUpdateAll, setPhaseBtnText } = frameloopSlice.actions;

export default frameloopSlice.reducer;
