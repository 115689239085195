import image1 from "./1.webp";
import image2 from "./2.webp";
import image3 from "./3.webp";
import image4 from "./4.webp";
import image5 from "./5.webp";
import image6 from "./6.webp";
import image7 from "./7.webp";
import image8 from "./8.webp";
import image9 from "./9.webp";
import image10 from "./10.webp";
import image11 from "./11.webp";
import image12 from "./12.webp";
import image13 from "./13.webp";
import image14 from "./14.webp";
import image15 from "./15.webp";
import image16 from "./16.webp";

const landingImages = [
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10,
  image11,
  image12,
  image13,
  image14,
  image15,
  image16,
];

export default landingImages;
