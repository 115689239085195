import { createSlice } from "@reduxjs/toolkit";
// import {appState}
import { HYDRATE } from "next-redux-wrapper";

// Initial state
const initialState = {
  meshesCount: 0,
};

// Actual Slice
export const configuratorSlice = createSlice({
  name: "configurator",
  initialState,
  reducers: {
    resetMeshesCount(state) {
      state.meshesCount = 0;
    },
    setMeshesCount(state, action) {
      state.meshesCount = action.payload;
    },
  },

  // Special reducer for hydrating the state. Special case for next-redux-wrapper
  extraReducers: {
    [HYDRATE]: (state, action) => {
      return {
        ...state,
        ...action.payload.configurator,
      };
    },
  },
});

export const { setMeshesCount, resetMeshesCount } = configuratorSlice.actions;

export default configuratorSlice.reducer;
