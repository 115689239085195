import { createSlice } from "@reduxjs/toolkit";
// import {appState}
import { HYDRATE } from "next-redux-wrapper";

// Initial state
const initialState = {
  showBackgroundColor: false,
  showHDR: false,
  showExtra: false,
  showCamera: false,
  showModelSettings: false,
};

// Actual Slice
export const configurator3DToolsSlice = createSlice({
  name: "configurator3DTools",
  initialState,
  reducers: {
    resetConfigurator3DTools(state) {
      state.showBackgroundColor = false;
      state.showHDR = false;
      state.showExtra = false;
    },
    setShow3DUI(state, action) {
      state.showBackgroundColor = action.payload.showBackgroundColor;
      state.showHDR = action.payload.showHDR;
      state.showExtra = action.payload.showExtra;
      state.showCamera = action.payload.showCamera;
      state.showModelSettings = action.payload.showModelSettings;
    },
    setShowModelSettings(state, action) {
      state.showModelSettings = action.payload;
    },
    setShowCamera(state, action) {
      state.showCamera = action.payload;
    },
    setShowBackgroundColor(state, action) {
      state.showBackgroundColor = action.payload;
    },
    setShowHDR(state, action) {
      state.showHDR = action.payload;
    },
    setShowExtra(state, action) {
      state.showExtra = action.payload;
    },
  },

  // Special reducer for hydrating the state. Special case for next-redux-wrapper
  extraReducers: {
    [HYDRATE]: (state, action) => {
      return {
        ...state,
        ...action.payload.configurator3DTools,
      };
    },
  },
});

export const {
  setShowModelSettings,
  setShowCamera,
  resetConfigurator3DTools,
  setShowBackgroundColor,
  setShowHDR,
  setShowExtra,
  setShow3DUI,
} = configurator3DToolsSlice.actions;

export default configurator3DToolsSlice.reducer;
