import { createSlice } from "@reduxjs/toolkit";
// import {appState}
import { HYDRATE } from "next-redux-wrapper";

// Initial state
const initialState = {
  meshes: [],
  selectedLayer: "",
  showPatternColorPicker: false,
  generatePatternImg: false,
  showExpandedPattern: true,
  showLayers: false,
  showClipArt: false,
  showFilters: false,
  uploadedImages: [],
  showImageUpload: false,
  showPatternEditor: false,
  selectedId: null,
  showLayerEditor: false,
  textureView: false,
  viewSwitchActive: false,
  usedColors: [],
  textTemplate: [],
  colorPickerActive: false,
  colorPickerColor: "",
  selectedColorPickerColor: "",
  showImageGenAi: false,
};

// Actual Slice
export const configuratorToolsSlice = createSlice({
  name: "configuratorTools",
  initialState,
  reducers: {
    updateSelectedText(state, action) {
      const req = action.payload;

      state.meshes[req.selectedLayer].layers[req.selectedId].text = req.value;
    },
    setUploadedImagesInitial(state, action) {
      state.uploadedImages = action.payload;
    },
    setSelectedColorPickerColor(state, action) {
      state.selectedColorPickerColor = action.payload;
    },
    setColorPickerActive(state, action) {
      state.colorPickerActive = action.payload;
    },
    setColorPickerColor(state, action) {
      state.colorPickerColor = action.payload;
    },
    setTextTemplate(state, action) {
      state.textTemplate = action.payload;
    },
    setUsedColors(state, action) {
      // if (state.usedColors.length === 50) {
      //   state.usedColors.shift();
      // }

      // state.usedColors.push(action.payload);

      state.usedColors = action.payload;
    },
    setMeshesSetIterations(state, action) {
      const response = action.payload;
      // apply current change
      state.meshes[response.name].layers = response.layers;
      state.meshes[response.name].color = response.color;

      // check if array is full
      if (state.meshes[response.name].iterations.length === 11) {
        state.meshes[response.name].iterations.shift();
      }

      if (
        state.meshes[response.name].currentIteration ===
        state.meshes[response.name].iterations.length - 1
      ) {
        state.meshes[response.name].iterations.push(response.iterations);
      } else {
        state.meshes[response.name].iterations.splice(
          state.meshes[response.name].currentIteration + 1,
          state.meshes[response.name].iterations.length -
            1 -
            state.meshes[response.name].currentIteration
        );

        state.meshes[response.name].iterations.push(response.iterations);
      }

      // set current iteration to the new change
      state.meshes[response.name].currentIteration =
        state.meshes[response.name].iterations.length - 1;
    },
    resetConfiguratorTools(state) {
      state.meshes = [];
      state.selectedLayer = "";
      state.showPatternColorPicker = false;
      state.generatePatternImg = false;
      state.showExpandedPattern = true;
      state.showLayers = false;
      state.showClipArt = false;
      state.showFilters = false;
      state.uploadedImages = [];
      state.showImageUpload = false;
      state.showPatternEditor = false;
      state.selectedId = null;
      state.showLayerEditor = false;
      state.textureView = false;
      state.viewSwitchActive = false;
      state.showImageGenAi = false;
    },
    setShowLayerEditor(state, action) {
      state.showLayerEditor = action.payload;
    },
    updateMeshesResetId(state, action) {
      state.meshes = action.payload.meshes;
      state.selectedId = action.payload.selectedId;
    },
    setSelectedId(state, action) {
      state.selectedId = action.payload;
    },
    setMeshes(state, action) {
      state.meshes = action.payload;
    },
    setSelectedLayer(state, action) {
      state.selectedLayer = action.payload;
    },
    setShowPatternEditor(state, action) {
      state.showPatternEditor = action.payload;
    },
    setShowImageGenAi(state, action) {
      state.showImageGenAi = action.payload;
    },
    setShowUI(state, action) {
      state.showClipArt = action.payload.showClipArt;
      state.showLayers = action.payload.showLayers;
      state.showExpandedPattern = action.payload.showExpandedPattern;
      state.showFilters = action.payload.showFilters;
      state.showImageUpload = action.payload.showImageUpload;
      state.showPatternColorPicker = action.payload.showPatternColorPicker;
      state.showLayerEditor = action.payload.showLayerEditor;
      state.showPatternEditor = action.payload.showPatternEditor;
      state.showImageGenAi = action.payload.showImageGenAi;
    },
    setShowImageUpload(state, action) {
      state.showImageUpload = action.payload;
    },
    setUploadedImages(state, action) {
      state.uploadedImages.push(action.payload);
    },
    setGeneratePatternImages(state, action) {
      state.generatePatternImg = action.payload;
    },
    setShowExpandedPattern(state, action) {
      state.showExpandedPattern = action.payload;
    },
    setShowPatternColorPicker(state, action) {
      state.showPatternColorPicker = action.payload;
    },
    setShowLayers(state, action) {
      state.showLayers = action.payload;
    },
    setShowShapeStrokeFill(state, action) {
      state.showShapeStrokeFill = action.payload;
    },
    setShowClipart(state, action) {
      state.showClipArt = action.payload;
    },
    setShowFilters(state, action) {
      state.showFilters = action.payload;
    },
    setViewSwitchActive(state, action) {
      state.viewSwitchActive = action.payload;
    },
    setTextureView(state, action) {
      state.textureView = action.payload;
    },
  },

  // Special reducer for hydrating the state. Special case for next-redux-wrapper
  extraReducers: {
    [HYDRATE]: (state, action) => {
      return {
        ...state,
        ...action.payload.configurator,
      };
    },
  },
});

export const {
  setShowImageGenAi,
  setUploadedImagesInitial,
  setUsedColors,
  setMeshesSetIterations,
  resetConfiguratorTools,
  setTextureView,
  setViewSwitchActive,
  setShowLayerEditor,
  updateMeshesResetId,
  setShowUI,
  setGeneratePatternImages,
  setShowExpandedPattern,
  setShowPatternColorPicker,
  setShowLayers,
  setShowClipart,
  setShowFilters,
  setUploadedImages,
  setShowImageUpload,
  setShowPatternEditor,
  setMeshes,
  setSelectedLayer,
  setSelectedId,
  setTextTemplate,
  setColorPickerActive,
  setColorPickerColor,
  setSelectedColorPickerColor,
  updateSelectedText,
} = configuratorToolsSlice.actions;

export default configuratorToolsSlice.reducer;
