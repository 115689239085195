"use client";

import React, { createContext, useContext, useState } from "react";

const Context = createContext();

export const StateContext = ({ children }) => {
  const [canvas2d, setcanvas2d] = useState(null);

  return (
    <Context.Provider
      value={{
        canvas2d,
        setcanvas2d,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useStateContext = () => useContext(Context);
