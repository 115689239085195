import { createSlice } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";

// Initial state
const initialState = {
  diffuseMaps: {},
  diffuseMapOverlay: {},
};

// Actual Slice
export const diffuseMapsSlice = createSlice({
  name: "diffuseMaps",
  initialState,
  reducers: {
    resetDiffuseMaps(state) {
      state.diffuseMaps = {};
      state.diffuseMapOverlay = {};
    },
    setDiffuseMaps(state, action) {
      state.diffuseMaps = action.payload;
    },
    setDiffuseMapsWhiteStroke(state, action) {
      state.diffuseMapsWhiteStroke = action.payload;
    },
    setDiffuseMapOverlay(state, action) {
      state.diffuseMapOverlay = action.payload;
    },
    setAll(state, action) {
      state.diffuseMaps = action.payload.diffuseMaps;
      state.diffuseMapOverlay = action.payload.diffuseMapOverlay;
    },
  },

  // Special reducer for hydrating the state. Special case for next-redux-wrapper
  extraReducers: {
    [HYDRATE]: (state, action) => {
      return {
        ...state,
        ...action.payload.diffuseMaps,
      };
    },
  },
});

export const {
  setDiffuseMaps,
  setDiffuseMapsWhiteStroke,
  setDiffuseMapOverlay,
  setAll,
  resetDiffuseMaps,
} = diffuseMapsSlice.actions;

export default diffuseMapsSlice.reducer;
